import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField from "./inputField";
import PsContext from "../../../context";
import { numberToMonth, upperCase, S3_BUCKET_HOME_PATH } from "../../../utils";

const QualificationDetails = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    try {
      if (props.data && props.data[0][fieldName])
        return props.data[0][fieldName];
    } catch (er) {}
  };

  const personalField = (fieldName) => {
    try {
      if (props.personal && props.personal[fieldName])
        return props.personal[fieldName];
    } catch (er) {}
  };

  return (
    <>
      <InputField
        label="College Previously studied"
        value={upperCase(field("college_name"))}
      />

      <InputField
        label="Place of the College Previously studied"
        value={upperCase(field("college_place"))}
      />

      <InputField
        label="Name of the University"
        value={field("university_name")}
      />

      <InputField label="Studied Degree" value={field("studied_degree")} />
      <InputField label="Studied Major" value={field("studied_major")} />
      <InputField
        label="Month & Year of passing"
        value={field("pass_month") + " / " + field("pass_year")}
      />
      <InputField label="Register Number" value={field("register_no")} />

      <div className="table-responsive mt-3">
        <table className="table table-sm table-bordered hsc-mark-table">
          <thead>
            <tr className="bg-light">
              <th>Subject Name</th>
              <th>
                {S3_BUCKET_HOME_PATH == "kctuty/"
                  ? "CGPA Scored"
                  : "Marks Scored"}
              </th>
              <th>
                {" "}
                {S3_BUCKET_HOME_PATH == "kctuty/"
                  ? "Maximum CGPA"
                  : "Maximum Marks"}
              </th>
              <th>PERCENTAGE OF MARKS</th>
              <th>No.of Attempts</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{upperCase(item.subject_name)}</td>
                  <td align="right">{item.mark_scored}</td>
                  <td align="right">{item.mark_maximum}</td>
                  <td align="right">{item.percentage_of_marks}</td>
                  <td align="center">{item.no_of_attempts}</td>
                </tr>
              );
            })}
            {S3_BUCKET_HOME_PATH == "kctuty/" ? (
              ""
            ) : (
              <>
                <tr>
                  <td>Total</td>
                  <td align="right" className="bg-light fw-bold">
                    {field("scored_total")}
                  </td>
                  <td align="right" className="bg-light fw-bold">
                    {field("max_total")}
                  </td>
                  <td align="right" className="bg-light fw-bold">
                    {field("overall_percentage")}
                  </td>
                  <td></td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>

      <InputField
        label={
          S3_BUCKET_HOME_PATH == "kctuty/"
            ? "CGPA scored in Part III"
            : "Marks scored in Part III"
        }
        value={field("part3_scored")}
      />
      <InputField
        label="Total Marks in Part III"
        value={field("part3_total")}
      />

      {upperCase(personalField("community")) != "OC" && (
        <>
          <Row className="context_box_title mb-3 mt-3">
            <Col md={12}>
              <h5>Community Details </h5>
            </Col>
          </Row>

          <InputField
            label="Community"
            value={upperCase(personalField("community"))}
          />

          <InputField
            label="Name of the Caste"
            value={upperCase(personalField("caste"))}
          />

          <InputField
            label="Issuing Authority"
            value={upperCase(field("issuing_authority"))}
          />

          <InputField
            label="Certificate No"
            value={upperCase(field("community_certificate_no"))}
          />

          <InputField
            label="Date of Issue"
            value={upperCase(field("community_certificate_issue_on"))}
          />

          <InputField label="District" value={upperCase(field("district"))} />

          <InputField label="Taluk" value={upperCase(field("taluk"))} />
        </>
      )}
    </>
  );
};

export default QualificationDetails;
