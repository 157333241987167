import React, { useState, useContext, useEffect } from "react";
import $, { isNumeric } from "jquery";
import PsContext from "../../../context";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import StepMenu from "../stepMenu";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";

import { toast } from "react-hot-toast";
import { Spin, Select } from "antd";

import ConfirmModal from "../../confirmModal";
import {
  groupByMultiple,
  lettersOnly,
  lowerCase,
  momentDate,
  numberToMonth,
  upperCase,
  S3_BUCKET_HOME_PATH,
} from "../../../utils";
import {
  COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY,
  TALUKS,
} from "../../../utils/data";
import { Link } from "react-router-dom";
import { listUniversity } from "../../../models/fieldSettings";
const { Option } = Select;

const StepTwo = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [validated, setValidated] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [captcha, setCaptcha] = useState([]);
  const [applicant, setApplicant] = useState([]);
  const [hscSubjects, setHscSubjects] = useState([]);

  const [minMark, setMinMarks] = useState(70);
  const [maxMark, setMaxMark] = useState(200);
  const [community, setCommunity] = useState("");
  const [subjectCount, setSubjectCount] = useState(0);
  const [board, setBoard] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [savedMarks, setSavedMarks] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [universityList, setUniversityList] = useState([]);
  const [ugDegree, setUgDegree] = useState([]);
  const [ugDegreeList, setUgDegreeList] = useState([]);
  const [ugMajor, setUgMajor] = useState([]);
  const [studiedUg, setStudiedUg] = useState(null);

  const partName = (part) => {
    return part == 1
      ? "Language (Part I)"
      : part == 2
      ? "English (Part II)"
      : part == 3
      ? "Major & Allied (Part III)"
      : part == 4
      ? "Part IV"
      : part == 5
      ? "Part V"
      : "";
  };

  const getMarksInfo = (part) => {
    return {
      subject_id: part,
      subjectname: partName(part),
      mark_scored: "",
      mark_maximum: "",
      pass_month: 4,
      pass_year: new Date().getFullYear(),
      register_no: "",
      class_obtained: "",
      no_of_attempts: "",
      scored_total: "",
      max_total: "",
      part3_total: "",
      percentage_of_marks: "",
      overall_percentage: "",
      no_of_attempts: "",
    };
  };

  const [marks, setMarks] = useState([]);

  useEffect(() => {
    loadApplicantInfo();
    loadUgForPg();
    listUniversity().then((res) => res && setUniversityList(res));

    if (props.pageType && props.pageType == "edit") loadData();
  }, []);

  const loadData = () => {
    setDataLoader(true);
    setLoader(true);
    const form = new FormData();
    form.append("api_code", context.user.api_code);
    axios.post(ServiceUrl.PG.PERSONAL_MARKS_PREVIEW, form).then((res) => {
      if (res["data"].status == "1") {
        let d = res["data"].data.basic;
        let m = res["data"].data.marks;
        setBaisc(res["data"].data.basic);
        setSavedMarks(res["data"].data.marks);
        setCommunity(d.community);
        setBoard(d.board);

        let mr = [];
        m.map((item, i) => {
          mr.push({
            id: item.id,
            subject_id: item.subject_id,
            subjectname: item.subject_name,
            mark_scored: parseFloat(item.mark_scored),
            mark_maximum: parseFloat(item.mark_maximum),
            pass_month: item.pass_month,
            pass_year: item.pass_year,
            register_no: item.register_no,
            no_of_attempts: item.no_of_attempts,
            scored_total: item.scored_total,
            max_total: item.max_total,
            part3_total: item.part3_total,
            percentage_of_marks: item.percentage_of_marks,
          });
        });

        setMarks(mr);
        //if (m[0]["studied_degree"]) {
        //handleStudiedDegreeChange(m[0]["studied_degree"]);
        setStudiedUg(m[0]["studied_major"]);
        //}

        setDataLoader(false);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const marksField = (fieldName) => {
    if (savedMarks && savedMarks.length > 0 && savedMarks[0][fieldName])
      return savedMarks[0][fieldName];
  };

  useEffect(() => {
    //if (!props.pageType && props.pageType != "edit") {
    {
      let m = [];
      for (let i = 1; i <= 5; i++) {
        m.push(getMarksInfo(i));
      }
      setMarks(m);
    }
  }, []);

  const loadApplicantInfo = () => {
    try {
      setLoader(true);
      setApplicant([]);
      var form = new FormData();
      form.append("i__apiCode", context.api);
      axios.post(ServiceUrl.PG.APPLICANT_MIN_INFO, form).then((res) => {
        if (res["data"].status == "1") {
          let d = res["data"].data;

          setCommunity(d.community);
          setApplicant(d);
          setLoader(false);
        } else {
          toast.error(res["data"].message || "error");
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const loadUgForPg = () => {
    try {
      setLoader(true);
      setUgDegree([]);
      setUgDegreeList([]);
      axios.get(ServiceUrl.PG.UG_FOR_PG + "?type=pg").then((res) => {
        if (res["data"].status == "1") {
          var d = groupByMultiple(res["data"].data, function (obj) {
            return [obj.ugdegree];
          });
          setUgDegree(d);
          setUgDegreeList(res["data"].data);
        }
      });
      setLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (props.pageType && props.pageType == "edit") {
      if (savedMarks.length > 0)
        handleStudiedDegreeChange(savedMarks[0]["studied_degree"]);
    }
  }, [ugDegree, savedMarks]);

  const handleStudiedDegreeChange = (v) => {
    try {
      var d = ugDegreeList;
      var s = d.filter(function (obj) {
        return obj.ugdegree == v;
      });
      setUgMajor(s);
    } catch (error) {}
  };

  const handleMajorChange = (e) => {
    try {
      var v = e.target.value;
      var d = ugDegreeList;

      var index = d.findIndex(function (obj) {
        return obj.id == v;
      });

      if (index > -1) setStudiedUg(d[index].ugmajor);
    } catch (erro) {}
  };

  const days = () => {
    let rv = [];
    Array.from({ length: 31 }, (v, i) => {
      rv.push(<option value={i + 1}>{i + 1}</option>);
    });
    return rv;
  };

  const months = (sel) => {
    let rv = [];
    Array.from({ length: 12 }, (v, i) => {
      let n = ("0" + (i + 1)).slice(-2);
      rv.push(
        <option
          value={("0" + n).slice(-2)}
          selected={sel == i + 1 ? "selected" : ""}
        >
          {numberToMonth(n)}
        </option>
      );
    });
    return rv;
  };

  const years = (sel) => {
    let rv = [];
    Array.from({ length: 15 }, (v, i) => {
      let n = new Date().getFullYear() - i;
      rv.push(
        <option value={n} selected={sel == n ? "selected" : ""}>
          {n}
        </option>
      );
    });
    return rv;
  };

  const marksInputOnBlur = (item, e) => {
    let ds = e.target.dataset;
    let v = e.target.value;

    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      console.log(m[index]);
      let mark = m[index]["mark_maximum"];

      if (parseFloat(v) > parseFloat(mark)) {
        toast.error("Enter correct mark");
        e.target.value = "";
        return;
      }
      m[index]["mark_scored"] = v;
      setMarks(m);

      updtePercentage();
    }
  };

  const maxMarksInputOnBlur = (item, e) => {
    let ds = e.target.dataset;
    let v = e.target.value;

    /* if (
      parseFloat(v) < parseFloat(ds.min) ||
      parseFloat(v) > parseFloat(ds.max)
    ) {
      toast.error("Enter correct mark");
      e.target.value = "";
      return;
    }*/
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      let mark = m[index]["mark_scored"];
      if (parseFloat(v) < parseFloat(mark)) {
        toast.error("Maximum mark not less than scored mark");
        e.target.value = "";
        return;
      }
      m[index]["mark_maximum"] = v;
      setMarks(m);
      updtePercentage();
    }
  };

  useEffect(() => {
    if (marks.length > 0) updtePercentage();
  }, [JSON.stringify(marks)]);

  const updtePercentage = () => {
    let m = [...marks];
    m.map((item, i) => {
      try {
        if (
          item.mark_scored &&
          parseFloat(item.mark_scored) > 0 &&
          item.mark_maximum &&
          parseFloat(item.mark_maximum) > 0
        ) {
          let p =
            (parseFloat(item.mark_scored) / parseFloat(item.mark_maximum)) *
            100;
          item.percentage_of_marks = parseFloat(p).toFixed(3);
        }
      } catch (er) {}
    });
    setMarks(m);
  };

  const getSubjectOptions = () => {
    let rv = [];
    hscSubjects.map((item, i) => {
      let index = marks.findIndex((obj) => obj.subject_id == item.id);
      //if (index == -1) rv.push(<Option value={item.id}>{item.name}</Option>);
      if (index == -1) rv.push({ value: item.id, label: item.name });
    });
    return rv;
  };

  const handleSubjectChange = (index, v) => {
    let m = [...marks];
    m[index]["subject_id"] = v;
    setMarks(m);
  };

  const handleInputChange = (item, fieldName, e) => {
    let m = [...marks];
    let index = m.findIndex((obj) => obj.subject_id == item.subject_id);
    if (index > -1) {
      m[index][fieldName] = e.target.value;
      setMarks(m);
    }
    if (fieldName == "no_of_attempts" && e.target.value == 1) {
      if (m[index - 1]["no_of_attempts"] == 1) {
        m[index]["register_no"] = m[index - 1]["register_no"];
      } else {
        m[index]["register_no"] = m[index - 2]["register_no"];
      }
    }
  };

  const getTotalByFieldName = (fieldName) => {
    let m = marks;
    let total = 0;
    m.map((item, i) => {
      let v = item[fieldName];
      if (v && parseFloat(v) > 0) total = parseFloat(total) + parseFloat(v);
    });
    return parseFloat(total);
  };

  const getOverallpercentage = (fieldName) => {
    let m = marks;
    let total = 0;
    let scored = 0;
    m.map((item, i) => {
      if (item.mark_scored && parseFloat(item.mark_scored) > 0)
        scored = parseFloat(scored) + parseFloat(item.mark_scored);
      if (item.mark_maximum && parseFloat(item.mark_maximum) > 0)
        total = parseFloat(total) + parseFloat(item.mark_maximum);
    });
    let p = (parseFloat(scored) / parseFloat(total)) * 100;

    return p && isNumeric(p) ? parseFloat(p).toFixed(3) : 0;
  };

  const part3Total = (fieldName) => {
    let m = marks;
    let total = 0;
    let s = m.find((item) => item.subject_id == 3);
    if (s && s[fieldName]) {
      return parseFloat(s[fieldName]).toFixed(2);
    } else return 0;
  };

  const part3Percentage = (fieldName) => {
    let m = marks;
    let total = 0;
    let s = m.find((item) => item.subject_id == 3);
    if (s && s.percentage_of_marks) {
      return parseFloat(s.percentage_of_marks).toFixed(2);
    } else return 0;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      toast.error("Please fill all the required fields.");
      return;
    }

    let m = marks.find((item) => !item.subject_id);
    if (m) {
      toast.error("Please select all the subjects and marks");
      return;
    }

    setShowConfirm(true);
  };

  const submitForm = () => {
    setLoader(true);
    setShowConfirm(false);

    let url = ServiceUrl.PG.STEP_TWO;
    if (props.pageType && props.pageType == "edit")
      url = ServiceUrl.PG.UPDATE_STEP_TWO;

    axios.post(url, $("#frmStepII").serialize()).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");
        context.updateUser(res["data"].user);
        setLoader(false);
        setIsRedirect(true);
        if (props.pageType && props.pageType == "edit") props.history.push("/");
      } else {
        toast.error(res["data"].message || "Error");

        context.setLoader(false);
        setLoader(false);
        loadCaptcha();
      }
    });
  };

  const loadCaptcha = () => {
    try {
      setLoader(true);
      axios.get(ServiceUrl.ADMISSION.CAPTCHA).then((res) => {
        setCaptcha(res["data"]);
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <StepMenu activeKey="step2" pageType={props.pageType} />

            {dataLoader && (
              <div className="text-center my-5">
                <Spinner />
              </div>
            )}

            {!dataLoader && (
              <Card>
                <Card.Header>
                  <div className="float-start">
                    Name :{"  "}
                    <b className="text-uppercase">
                      {applicant.name} {applicant.initial}
                    </b>
                  </div>
                  <div className="float-end">
                    Registration No : {"  "}
                    <b>{applicant.user_code}</b>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Spin spinning={loader}>
                    <Form
                      noValidate
                      validated={validated}
                      encType="multipart/form-data"
                      id="frmStepII"
                      method="post"
                      onSubmit={handleFormSubmit}
                    >
                      <input
                        type="hidden"
                        name="i__userId"
                        value={context.user.user_id}
                      />
                      <input
                        type="hidden"
                        name="i__userCode"
                        value={context.user.user_code}
                      />
                      <input
                        type="hidden"
                        name="i__apiCode"
                        value={context.user.api_code}
                      />

                      <input
                        type="hidden"
                        name="i__studiedUg"
                        value={studiedUg}
                      />

                      <input
                        type="hidden"
                        name="marks"
                        value={JSON.stringify(marks)}
                      />

                      <label
                        className="text-danger"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Upload Latest Mark Statement OR Result Copy
                      </label>
                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Details of Qualification</h5>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            College Previously studied
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__collegeName"
                            size="sm"
                            defaultValue={marksField("college_name")}
                            placeholder="College Previously studied"
                            onKeyPress={(e) => lettersOnly(e)}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Place of the College Previously studied
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="college_place"
                            size="sm"
                            placeholder="Place of the College"
                            defaultValue={marksField("college_place")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Name of the University
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            as="select"
                            name="i__universityName"
                            defaultValue={marksField("university_name")}
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            required
                          >
                            <option value="">-Select-</option>
                            {universityList &&
                              universityList.length > 0 &&
                              universityList.map((item) => (
                                <option
                                  value={item.name}
                                  selected={
                                    marksField("university_name") == item.name
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {item.name}
                                </option>
                              ))}
                          </Form.Control>

                          {/* <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__universityName"
                            size="sm"
                            placeholder="Name of the University"
                            defaultValue={marksField("university_name")}
                            required
                          /> */}
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Studied Degree & Major
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="select"
                            size="sm"
                            className="fw-bold"
                            name="i__studiedDegree"
                            onChange={(e) =>
                              handleStudiedDegreeChange(e.target.value)
                            }
                            required
                          >
                            <option value=""> -Select- </option>
                            {ugDegree.map((item, i) => {
                              return (
                                <option
                                  value={item[0].ugdegree}
                                  selected={
                                    item[0].ugdegree ==
                                    marksField("studied_degree")
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {" "}
                                  {item[0].ugdegree}{" "}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="select"
                            size="sm"
                            className="fw-bold"
                            name="i__studiedMajor"
                            onChange={(e) => handleMajorChange(e)}
                            required
                          >
                            <option value=""> -Select- </option>
                            {ugMajor.map((item, i) => {
                              return (
                                <option
                                  value={item.id}
                                  data-id={item.id}
                                  selected={
                                    item.id == marksField("studied_ug")
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {" "}
                                  {upperCase(item.ugmajor)}{" "}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Month & Year of Passing
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="select"
                            size="sm"
                            className="fw-bold"
                            name="i__pgPassMonth"
                            required
                          >
                            {months(
                              marksField("pass_month")
                                ? marksField("pass_month")
                                : 4
                            )}
                          </Form.Control>
                        </Col>
                        <Col md={4}>
                          <Form.Control
                            as="select"
                            size="sm"
                            className="fw-bold"
                            name="i__pgPassYear"
                            required
                          >
                            {years(marksField("pass_year"))}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={4}>
                          <label>
                            Register Number
                            <span className="text-danger"> *</span>
                          </label>
                        </Col>
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="i__pgRegisterNumber"
                            size="sm"
                            placeholder="Register Number"
                            defaultValue={marksField("register_no")}
                            maxLength={16}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Details of Marks Obtained</h5>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={12}>
                          <div className="table-responsive">
                            <table className="table table-sm table-bordered hsc-mark-table">
                              <thead className="bg-light">
                                <tr>
                                  <th width="150">Subject</th>
                                  <th>
                                    {S3_BUCKET_HOME_PATH == "kctuty/"
                                      ? "CGPA Scored"
                                      : "Marks Scored"}
                                  </th>
                                  <th>
                                    {S3_BUCKET_HOME_PATH == "kctuty/"
                                      ? "Maximum CGPA"
                                      : "Maximum Marks"}
                                  </th>
                                  <th>Percentage of Marks</th>
                                  <th>No.of Attempts</th>
                                </tr>
                              </thead>
                              <tbody>
                                {marks.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {item.subjectname ==
                                        "Language (Part 1)" ? (
                                          <>
                                            {item.subjectname}{" "}
                                            <Form.Control
                                              type="text"
                                              size="sm"
                                              className="cl__no__arrow i__mark_scored fw-bold"
                                              name="part1_language"
                                              defaultValue="Tamil"
                                            />
                                          </>
                                        ) : (
                                          item.subjectname
                                        )}
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          onWheel={(e) => e.target.blur()}
                                          onBlur={(e) =>
                                            marksInputOnBlur(item, e)
                                          }
                                          min={1}
                                          required
                                          defaultValue={item.mark_scored}
                                          step=".01"
                                          disabled={!item.subject_id}
                                        />
                                      </td>
                                      <td>
                                        {S3_BUCKET_HOME_PATH == "tdmns/" ||
                                        S3_BUCKET_HOME_PATH == "kctuty/" ? (
                                          <>
                                            <Form.Control
                                              type="number"
                                              size="sm"
                                              className="cl__no__arrow i__mark_scored fw-bold"
                                              id="maxmark"
                                              disabled={!item.subject_id}
                                              value="10"
                                              min={1}
                                              onBlur={(e) =>
                                                maxMarksInputOnBlur(item, e)
                                              }
                                              required
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <Form.Control
                                              type="number"
                                              size="sm"
                                              className="cl__no__arrow i__mark_scored fw-bold"
                                              disabled={!item.subject_id}
                                              defaultValue={item.mark_maximum}
                                              id="maxmark"
                                              min={1}
                                              onBlur={(e) =>
                                                maxMarksInputOnBlur(item, e)
                                              }
                                              required
                                            />
                                          </>
                                        )}
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          value={item.percentage_of_marks || ""}
                                          disabled={!item.subject_id}
                                          min={1}
                                          step="any"
                                          required
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          size="sm"
                                          className="cl__no__arrow i__mark_scored fw-bold"
                                          defaultValue={item.no_of_attempts}
                                          disabled={!item.subject_id}
                                          min={1}
                                          onChange={(e) =>
                                            handleInputChange(
                                              item,
                                              "no_of_attempts",
                                              e
                                            )
                                          }
                                          required
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              {S3_BUCKET_HOME_PATH == "kctuty/" ? (
                                ""
                              ) : (
                                <>
                                  <tfoot>
                                    <tr className="bg-light">
                                      <td>Total</td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          className="fw-bold"
                                          size="sm"
                                          name="scored_total"
                                          value={getTotalByFieldName(
                                            "mark_scored"
                                          )}
                                          required
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          className="fw-bold"
                                          size="sm"
                                          name="max_total"
                                          value={getTotalByFieldName(
                                            "mark_maximum"
                                          )}
                                          required
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="text"
                                          className="fw-bold"
                                          size="sm"
                                          name="overall_percentage"
                                          value={getOverallpercentage()}
                                          required
                                        />
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tfoot>{" "}
                                </>
                              )}
                            </table>
                          </div>
                        </Col>
                      </Row>

                      <Row className=" pb-2 border-bottom">
                        <Col md={3}>
                          <label>
                            {S3_BUCKET_HOME_PATH == "kctuty/"
                              ? "CGPA scored in Part III"
                              : "Marks scored in Part III"}
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={2}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="part3_scored"
                            size="sm"
                            value={part3Total("mark_scored")}
                            required
                          />
                        </Col>
                        <Col md={2}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="part3_total"
                            size="sm"
                            value={part3Total("mark_maximum")}
                            required
                          />
                        </Col>
                        <Col md={2}>
                          <Form.Control
                            type="text"
                            className="text-uppercase fw-bold"
                            name="part3_percentage"
                            size="sm"
                            value={part3Percentage("mark_maximum")}
                            required
                          />
                        </Col>
                      </Row>

                      {lowerCase(community) == "oc" && (
                        <input
                          type="hidden"
                          name="i__community"
                          value={lowerCase(community)}
                        />
                      )}
                      {S3_BUCKET_HOME_PATH == "tdmns/" ||
                      S3_BUCKET_HOME_PATH == "stjeromes/" ? (
                        <>
                          {lowerCase(community) != "oc" && (
                            <>
                              <Row className="context_box_title mt-2">
                                <Col md={12}>
                                  <h5>Community certificate details</h5>
                                </Col>
                              </Row>
                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Community
                                    <span className="text-danger"></span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__community"
                                    size="sm"
                                    value={upperCase(community)}
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Name of the Caste
                                    <span className="text-danger"></span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__caste"
                                    size="sm"
                                    defaultValue={applicant.caste}
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Issuing Authority
                                    <span className="text-danger"></span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    as="select"
                                    className="text-uppercase fw-bold"
                                    name="i__issuingAuthority"
                                    size="sm"
                                    defaultValue={marksField(
                                      "issuing_authority"
                                    )}
                                  >
                                    <option value="">-Select-</option>
                                    {COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY.map(
                                      (item) => (
                                        <option
                                          value={item}
                                          selected={
                                            item ==
                                            marksField("issuing_authority")
                                              ? "selected"
                                              : ""
                                          }
                                        >
                                          {item}
                                        </option>
                                      )
                                    )}
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Certificate No
                                    <span className="text-danger"></span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__certificateNo"
                                    defaultValue={marksField(
                                      "community_certificate_no"
                                    )}
                                    size="sm"
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Date of Issue
                                    <span className="text-danger"></span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="date"
                                    className="text-uppercase fw-bold"
                                    name="i__issueDate"
                                    size="sm"
                                    defaultValue={marksField(
                                      "community_certificate_issue_on"
                                    )}
                                    max={momentDate(new Date(), "YYYY-MM-DD")}
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    District
                                    <span className="text-danger"></span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__district"
                                    size="sm"
                                    defaultValue={marksField("district")}
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Taluk
                                    <span className="text-danger"></span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__taluk"
                                    defaultValue={marksField("taluk")}
                                    size="sm"
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          {lowerCase(community) != "oc" && (
                            <>
                              <Row className="context_box_title mt-2">
                                <Col md={12}>
                                  <h5>Community certificate details</h5>
                                </Col>
                              </Row>
                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Community
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__community"
                                    size="sm"
                                    value={upperCase(community)}
                                    required
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Name of the Caste
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__caste"
                                    size="sm"
                                    defaultValue={applicant.caste}
                                    required
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Issuing Authority
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    as="select"
                                    className="text-uppercase fw-bold"
                                    name="i__issuingAuthority"
                                    size="sm"
                                    defaultValue={marksField(
                                      "issuing_authority"
                                    )}
                                    required
                                  >
                                    <option value="">-Select-</option>
                                    {COMMUNITY_CERTIFICATE_ISSUING_AUTHORITY.map(
                                      (item) => (
                                        <option
                                          value={item}
                                          selected={
                                            item ==
                                            marksField("issuing_authority")
                                              ? "selected"
                                              : ""
                                          }
                                        >
                                          {item}
                                        </option>
                                      )
                                    )}
                                  </Form.Control>
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Certificate No
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__certificateNo"
                                    defaultValue={marksField(
                                      "community_certificate_no"
                                    )}
                                    size="sm"
                                    required
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Date of Issue
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="date"
                                    className="text-uppercase fw-bold"
                                    name="i__issueDate"
                                    size="sm"
                                    defaultValue={marksField(
                                      "community_certificate_issue_on"
                                    )}
                                    max={momentDate(new Date(), "YYYY-MM-DD")}
                                    required
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    District
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  <Form.Control
                                    type="text"
                                    className="text-uppercase fw-bold"
                                    name="i__district"
                                    size="sm"
                                    defaultValue={marksField("district")}
                                    required
                                  />
                                </Col>
                              </Row>

                              <Row className="py-2 border-bottom">
                                <Col md={4}>
                                  <label>
                                    Taluk
                                    <span className="text-danger">*</span>
                                  </label>
                                </Col>
                                <Col md={8}>
                                  {S3_BUCKET_HOME_PATH == "tdmns/" ? (
                                    <Form.Control
                                      as="select"
                                      name="i__taluk"
                                      defaultValue={marksField("taluk")}
                                      size="sm"
                                      className="fw-bold form-select form-select-sm"
                                      required
                                    >
                                      <option value="">-Select-</option>
                                      {TALUKS.map((item) => (
                                        <option value={item.value}>
                                          {item.text}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  ) : (
                                    <Form.Control
                                      type="text"
                                      className="text-uppercase fw-bold"
                                      name="i__taluk"
                                      defaultValue={marksField("taluk")}
                                      size="sm"
                                      required
                                    />
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}

                      <Row className="mt-4">
                        {props.pageType && props.pageType == "edit" && (
                          <Col md={6}>
                            <Link to="/" className="btn btn-danger w-100">
                              Cancel
                            </Link>
                          </Col>
                        )}
                        <Col
                          md={
                            props.pageType && props.pageType == "edit" ? 6 : 12
                          }
                        >
                          <Button
                            type="submit"
                            variant="success"
                            className="w-100"
                            disabled={loader}
                          >
                            Save & Continue
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>

      <ConfirmModal
        title="Confirm"
        content={
          <div>
            Please check all the filled informations before processing. Do you
            want to proceed next ?
          </div>
        }
        confirmText="Save & Proceed"
        show={showConfirm}
        onHide={(e) => setShowConfirm(false)}
        onCancel={(e) => setShowConfirm(false)}
        onConfirm={(e) => submitForm()}
      />
    </>
  );
};

export default StepTwo;
